.default-option {
  background: #fff;

  &:hover {
    background: #ebf5f9;
  }

  &_selected {
    background: #d6eaf2 !important;
  }
}
