/* GRID */
h3.h3 {
  font-size: 24px;
}

h4.h4 {
  font-size: 20px;
}

h5.h5 {
  font-size: 16px;
}

.wrapper {
  border: 20px solid #e5e5e5;
  min-height: calc(100vh - 202px);
}

/* BUTTON */
.btn {
  border-radius: 2px;
  /* padding: 9px 24px; */
  font-size: 14px;
  letter-spacing: normal;
  line-height: 20px;
  margin: 0;
}

button:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  box-shadow: none;
  outline: none;
}

.btn:hover,
.btn:focus {
  text-decoration: none;
}

.btn:active,
.btn.active {
  background-image: none;
  box-shadow: none;
  outline: none;
}

/* SIZE SMALL */
.btn-sm,
.btn-group-sm > .btn {
  border-radius: 2px;
  padding: 5px 16px !important;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 16px !important;
}

/* SCHEME DEFAULT */
.btn-default {
  background-color: #fff;
  border-color: #d0d0ce;
  color: #000;
  font-size: 12px;
}

.wrapper-btn {
  border: none;
  padding: 0;
  text-align: left;
  width: 100%;
}

/* .btn-default:hover, */
.open > .dropdown-toggle.btn-default {
  background-color: #fff;
  border-color: #00a3e0;
  color: #c6c9ca !important;
}

.btn-default:focus {
  background-color: #fff;
  /* border-color: #d0d0ce;
      box-shadow: 0 0 2px 0 #00a3e0; */
  box-shadow: none;
  color: #000;
}

.btn-open {
  background-color: #fff;
  border-color: #00a3e0;
  color: #00a3e0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.btn-open:hover,
.btn-open:active,
.btn-open:focus,
.btn-open:focus-within,
.btn-open:not(:disabled):not(.disabled):active {
  background-color: #fff;
  border-color: #00a3e0;
  color: #00a3e0;
}

.btn-border-light {
  background-color: #fff;
  border-color: lightgrey;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.btn-border-light:hover,
.btn-border-light:active,
.btn-border-light:focus,
.btn-border-light:focus-within,
.btn-border-light:not(:disabled):not(.disabled):active {
  background-color: #fff;
  border-color: lightgrey;
  color: #000;
}

/* .btn-default:active,
  .btn-default.active, */
.open > .dropdown-toggle.btn-default {
  background-color: #fff;
  border-color: #0082b3;
  color: #0082b3;
}

.btn-normal {
  background-color: #fff;
  border-color: #00a3e0;
  color: #00a3e0;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default {
  background-color: #fff !important;
  border-color: #e6e6e6 !important;
  color: #bbb !important;
}

/* SCHEME PRIMARY */
.btn-primary {
  background-color: #007cb0;
  border-color: #007cb0;
  color: #fff;
  font-size: 14px;
}

.btn-outline-primary {
  /* background-color: #00a3e0; */
  border-color: #007cb0;
  color: #007cb0;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: rgba(0, 163, 224, 12%);
  border-color: #007cb0;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  color: #007cb0;
  background-color: rgba(0, 163, 224, 6%);
  border-color: #007cb0;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}

.btn-cancel {
  color: #00a3e0;
}

.open > .dropdown-toggle.btn-primary {
  background-color: #0092c9;
  border-color: #0092c9;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background-color: #00a3e0;
  border-color: #00a3e0;
  /* color: #fff;
      background-color: #00a3e0;
      border-color: #00a3e0;
      box-shadow: 0 0 2px 0 #0092c9; */
  box-shadow: none;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #00a3e0;
  border-color: #00a3e0;
  color: #fff;
}

.open > .dropdown-toggle.btn-primary {
  background-color: #0082b3;
  border-color: #0082b3;
  color: #fff;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary {
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
  color: #c2c3c3 !important;
}

/* SCHEME SUCCESS */
.btn-success {
  background-color: #86bd24;
  border-color: #86bd24;
  color: #fff;
}

.open > .dropdown-toggle.btn-success {
  background-color: #7ca807;
  border-color: #7ca807;
  color: #fff;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active {
  background-color: #7ca807;
  border-color: #7ca807;
  /* color: #fff;
      background-color: #86BD24;
      border-color: #86BD24;
      box-shadow: 0 0 2px 0 #7ca807; */
}

.open > .dropdown-toggle.btn-success {
  background-color: #6f9607;
  border-color: #6f9607;
  color: #fff;
}

.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success {
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
  color: #c2c3c3 !important;
}

/* FORM CONTROL */
.form-control {
  border: 1px solid #a7a8aa;
  border-radius: 1px;
  box-shadow: none;
  color: #000;
  display: block;
  font-size: 13px;
  height: 32px;
  letter-spacing: 0;
  line-height: 24px;
  border-radius: 1px;
  box-shadow: none;
  transition: border-color ease-in-out 0.1s, box-shadow ease-in-out 0.1s;
}

.form-control:focus {
  border-color: #66afe9;
  box-shadow: 0 0 2px rgba(102, 175, 233, 0.6);
  outline: 0;
}

.form-control::placeholder {
  color: #bcbcbc;
  opacity: 1;
}

.form-control:input-placeholder {
  color: #bcbcbc;
}

.form-control::input-placeholder {
  color: #bcbcbc;
}

.form-control[readonly] {
  background-color: #f6f6f6 !important;
  border-color: #e6e6e6 !important;
  color: #000 !important;
  cursor: not-allowed;
  opacity: 1 !important;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  background-color: #f6f6f6 !important;
  border-color: #e6e6e6 !important;
  color: #bcbcbc !important;
  cursor: not-allowed;
  opacity: 1 !important;
}

textarea.form-control {
  height: auto;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-color: #fceeed;
  border: 1px solid #da291c;
}

.red {
  color: #da291c;
  font-size: 14px;
  font-weight: 600;
}

/* addons */
.input-group-addon {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  padding: 6px 12px;
  text-align: center;
}

/* input dropdown */
.form-control.input-dropdown {
  padding-right: 40px;
}

.form-control.input-dropdown::after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #333;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  right: 15px;
  top: 17px;
  width: 0;
}

/* DROPDOWN */
.dropdown {
  position: relative;
}

.dropdown > .btn {
  color: #333;
  /* padding-right: 32px; */
  overflow: hidden;
  /* min-width: 220px;
      max-width: 350px; */
  padding: 0 16px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.dropdown > .btn:hover,
.dropdown.open > .btn {
  color: #333;
}

.dropdown > .btn > .caret {
  position: absolute;
  right: 10px;
  top: 17px;
}

.dropdown-menu {
  border-radius: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  font-size: 14px;
  margin: 1px 0 0;
  min-width: 160px;
  padding: 4px 0;
  text-align: left;
}

.open > .dropdown-menu,
.open > .dropdown-box {
  display: block;
}

/*box*/
.dropdown-box {
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(24, 25, 26, 0.2);
  color: #000;
  cursor: default;
  display: none;
  font-size: 14px;
  letter-spacing: normal;
  margin: 1px 0 0;
  max-width: 800px;
  min-width: 270px;
  padding: 4px 0;
  position: absolute;
  z-index: 12345;
}

.dropdown-box-scrollable {
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

/*list*/
.dropdown-list {
  white-space: nowrap;
}

.dropdown-list:not(:first-child) {
  border-top: 1px solid #dcdcdc;
  font-size: 14px;
  margin-top: 4px;
  padding-top: 4px;
}

.dropdown-list-item {
  cursor: pointer;
  display: block;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.1s ease;
}

.dropdown-list-item:hover {
  background-color: rgba(0, 162, 224, 0.1);
}

/* select */
.dropdown-list-item-select.dropdown-list-item {
  padding-left: 40px;
}

.dropdown-list-item-select.dropdown-list-item.state_checked {
  font-weight: 600;
}

.dropdown-list-item-select-checkbox {
  border: 1px solid #bcbcbc;
  border-radius: 2px;
  color: #fff;
  font-size: 10px;
  height: 16px;
  left: 16px;
  line-height: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  transition: background-color 0.1s ease, border-color 0.1s ease;
  width: 16px;
}

.dropdown-list-item-select-checkbox.state_checked {
  background-color: #00a3e0;
  border-color: #00a3e0;
}

.dropdown-text {
  padding: 8px 16px;
  user-select: all;
  user-select: all;
  user-select: all;
  user-select: all;
}

.dropdown-text:not(:first-child) {
  border-top: 1px solid #f1f1f1;
}

.modal-content {
  border-radius: 0;
}

.modal-footer {
  background: white;
}

.btn-primary.btn-outline {
  color: white !important;
}

.modal-footer {
  border-top: none;
}

.modal-header + .modal-footer {
  margin-top: -1px;
}

.btn-success.btn-outline {
  color: white;
}

.btn-group .btn-default:hover {
  background-color: white !important;
  border-color: #d0d0ce !important;
  color: black !important;
}

.btn-group .btn-default.active {
  background-color: #d0d0ce !important;
  border-color: #d0d0ce !important;
  color: black !important;
}

.btn-group._small .btn {
  padding: 9px 12px !important;
}

.tooltip {
  opacity: 1 !important;
}

.tooltip .tooltip-inner {
  background: #53565a !important;
  border-radius: 0;
  padding: 8px 16px;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #53565a !important;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #53565a !important;
}

.tooltip.left .tooltip-arrow {
  border-left-color: #53565a !important;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #53565a !important;
}

.btn-danger {
  background-color: #e30613 !important;
  border-color: #e30613;
  color: #fff !important;
}

.btn-danger[disabled] {
  background-color: #fff !important;
  border-color: #e6e6e6 !important;
  color: #bbb !important;
}

.check-control input[type='checkbox'],
.check-control input[type='radio'] {
  display: none;
}

.check-control label {
  margin-bottom: 0;
}

/*Check box*/
input[type='checkbox'] + .label-text::before {
  color: #d0d0ce;
  content: '\E917';
  display: inline-block;
  font-family: 'icomoon';
  font-size: 20px;
  line-height: 2;
  margin-right: 7px;
  position: relative;
  top: 3px;
}

input[type='checkbox']:checked + .label-text::before {
  color: #00a3e0;
  content: '\e919';
}

input[type='checkbox']:disabled + .label-text {
  color: #c2c3c3;
}

input[type='checkbox']:disabled + .label-text::before {
  color: #c2c3c3;
}

/*Radio box*/

input[type='radio'] + .label-text::before {
  color: #d0d0ce;
  content: '\e914';
  display: inline-block;
  font-family: 'icomoon';
  font-size: 20px;
  margin-right: 5px;
  width: 1em;
}

input[type='radio']:checked + .label-text::before {
  color: #00a3e0;
  content: '\e915';
}

input[type='radio']:disabled + .label-text {
  color: #c2c3c3;
}

input[type='radio']:disabled + .label-text::before {
  color: #c2c3c3;
}

.accordion__button {
  position: relative;
}

.accordion__button::before {
  border-bottom: 1px solid #000;
  border-right: none;
  content: '';
  display: inline-block;
  height: 10px;
  margin-right: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(-135deg);
  transition: 0.3s;
  width: 10px;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(135deg);
}

.hidden-visually {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
