@import '../../../styles/colors';
@import '../../../styles/screens';
@import '../../../styles/fonts';
@import '../../../styles/shared';
@import '../../../styles/icon/style.css';

.highlight {
  color: $button-blue;
  font-weight: 600;
}
