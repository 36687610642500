.Toastify__toast.toastScanFailed,
.Toastify__toast.customToastForSettings {
  padding: 0;

  .Toastify__toast-body {
    padding: 0;
  }

  .toast {
    flex-direction: column;
    padding-right: 0 !important;
    width: 100%;
    display: block;

    &__content__wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px;
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #000;
    }

    &__icon {
      display: flex;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-right: 16px;
      border-radius: 50%;
    }

    &__actions {
      padding: 16px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      border-top: 1px solid #d0d0ce;
    }
  }
}
