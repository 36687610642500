@import '../../styles/colors.scss';

.rotate-icon {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.themed-select-checkmark__option {
  &--is-selected {
    position: relative;
    padding-right: 15px;
    &::before {
      content: '';
      width: 15px;
      height: 11px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='11' viewBox='0 0 15 11' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.1464 0.146447C14.3417 -0.0488155 14.6583 -0.0488155 14.8536 0.146447C15.0271 0.320013 15.0464 0.589437 14.9114 0.784305L14.8536 0.853553L4.85355 10.8536C4.67999 11.0271 4.41056 11.0464 4.21569 10.9114L4.14645 10.8536L0.146447 6.85355C-0.0488155 6.65829 -0.0488155 6.34171 0.146447 6.14645C0.320013 5.97288 0.589437 5.9536 0.784305 6.08859L0.853553 6.14645L4.5 9.793L14.1464 0.146447Z' fill='%23007CB0'/%3E%3C/svg%3E");
      position: absolute;
      right: 12px;
      top: 14px;
    }
  }
}

.themed-select,
.themed-select-error {
  &__option {
    &--is-disabled {
      opacity: 0.5;

      &:hover {
        background: transparent !important;
        cursor: not-allowed;
      }
    }
  }
}

.themed-select-wrapper > .field-error-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $light-red;
  margin-top: 8px;
}
