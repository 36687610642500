@font-face {
  font-family: 'OpenSans-Bold';
  src: url(../fonts/OpenSans-Bold.woff2);
}

@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: url(../fonts/OpenSans-BoldItalic.woff2);
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url(../fonts/OpenSans-ExtraBold.woff2);
}

@font-face {
  font-family: 'OpenSans-ExtraBoldItalic';
  src: url(../fonts/OpenSans-ExtraBoldItalic.woff2);
}

@font-face {
  font-family: 'OpenSans-Italic';
  src: url(../fonts/OpenSans-Italic.woff2);
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url(../fonts/OpenSans-Light.woff2);
}

@font-face {
  font-family: 'OpenSans-LightItalic';
  src: url(../fonts/OpenSans-LightItalic.woff2);
}

@font-face {
  font-family: 'Open Sans';
  src: url(../fonts/OpenSans-Regular.woff2);
}

@font-face {
  font-family: 'OpenSans-Semibold';
  src: url(../fonts/OpenSans-Semibold.woff2);
}

@font-face {
  font-family: 'OpenSans-SemiboldItalic';
  src: url(../fonts/OpenSans-SemiboldItalic.woff2);
}
@font-face {
  font-family: 'DejaVu Sans';
  src: url(../fonts/DejaVuSans.woff2);
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  src: url(../fonts/DejaVuSans-Bold.woff2);
}

@font-face {
  font-family: 'DejaVu Sans';
  font-style: italic;
  src: url(../fonts/DejaVuSans-Oblique.woff2);
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  font-style: italic;
  src: url(../fonts/DejaVuSans-Oblique.woff2);
}
body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

.app-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  z-index: 0;
}
