@import '../../../styles/colors';

.toast {
    max-width: 466px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid $color-hat-control-border;

        &__text {
            display: flex;
            gap: 16px;
            align-items: center;
        }
    }

    &__content {
        padding: 16px 24px;

        &__message {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $dropdown-placeholder-color;
        }
    }
}
