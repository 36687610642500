.overlay {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.5;
  outline: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1100;
}

.loader {
  left: 50%;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1110;

  img {
    height: 64px;
    width: 64px;
  }
}
