@import '../../../styles/colors.scss';

.Deloitte-spinner {
  color: $button-blue;
  & > g {
    transform-origin: center;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
