.Toastify__toast-container {
  width: auto;
}

.Toastify__progress-bar {
  &--error {
    background-color: rgba(218, 41, 28, 0.3);
  }

  &--success {
    background-color: rgba(38, 137, 13, 0.3);
  }
}

.custom-toast {
  background-color: #fff;
  width: 400px;

  &__icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-right: 16px;
    width: 32px;
  }

  &__toast-close-btn {
    height: 15px;
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;

    &::before,
    &::after {
      background-color: #000;
      content: '';
      display: block;
      height: 1px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 100%;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__body {
    align-items: center;
    color: #000;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding-right: 40px;
    z-index: 1002;
    overflow-wrap: anywhere;
  }
}
