@font-face {
    font-family: 'icomoon';
    src: url('icomoon.eot?oguqgr');
    src: url('icomoon.eot?oguqgr#iefix') format('embedded-opentype'),
    url('icomoon.ttf?oguqgr') format('truetype'),
    url('icomoon.woff?oguqgr') format('woff'),
    url('icomoon.svg?oguqgr#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-ic-check-approve:before {
    content: "\e901";
}

.icon-ic-close-cancel:before {
    content: "\e902";
}

.icon-Decline:before {
    content: "\e903";
}

.icon-ic-download:before {
    content: "\e904";
}

.icon-Error:before {
    content: "\e905";
}

.icon-ic-chevron-down:before {
    content: "\e906";
}

.icon-ic-chevron-up:before {
    content: "\e907";
}

.icon-ic-pagination-back-end:before {
    content: "\e908";
}

.icon-ic-pagination-back:before {
    content: "\e909";
}

.icon-ic-pagination-next-end:before {
    content: "\e90a";
}

.icon-ic-pagination-next:before {
    content: "\e90b";
}

.icon-Play:before {
    content: "\e90c";
}

.icon-ic-attention-alert-circled:before {
    content: "\e900";
}

.icon-ic-confirmation-circled:before {
    content: "\e90d";
}
