
.user-info {
  align-items: center;
  display: flex;

  .admin-tool {
    font-size: 14px;
    font-weight: 400;

    svg {
      margin-right: 10px;
      margin-top: -3px;
    }

    .dashboard-icon {
      svg {
        margin-left: 4px;
        margin-right: 14px;
      }
    }

    a {
      color: #000;
    }
  }

  .icon-chevron {
    color: #d8d8d8;
    font-size: 18px;
  }

  .dropdown {
    background: transparent;
    border: none;
    height: auto;
    min-width: auto;

    button {
      background: transparent;
      border: none;
    }
  }

  .show.dropdown {
    .icon-chevron {
      transform: rotate(180deg);
    }
  }

  .item-selected {
    border: 1px solid #d0d0ce !important;
    padding-bottom: 3px;
  }

  .dropdown-menu {
    background-color: #fff;
    border: none;
    border-radius: 2px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24);
    cursor: auto;
    padding: 0;
    transform: translate3d(-274px, 28px, 0);
    width: 320px;

    .user-picture {
      border-radius: 50%;
      font-size: 20px;
      font-weight: 600;
      height: 48px;
      line-height: 38px;
      margin-bottom: 80px;
      margin-right: 16px;
      min-width: 48px;
      text-align: center;
      width: 48px;
    }
  }

  .email,
  .name {
    color: #555;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    word-break: break-all;
  }

  .name {
    font-weight: 600;
  }

  .btn {
    padding: 0;
  }

  .dropdown-toggle::after {
    display: none;
    left: 28px;
    position: absolute;
    top: 21px;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

}

.user-picture {
  align-items: center;
  background: #53565a;
  color: #fff;
  display: flex;
  font-size: 13px;
  font-weight: normal;
  height: 36px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  width: 36px;
  // border-radius: 50%;
}

.main-info {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 16px;
}

.button-wrap {
  display: flex;
  padding-top: 16px;
}

.profile-info {
  padding: 16px;
}

.lang-label {
  font-size: 14px;
}

.language-warning {
  color: #75787b;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.8;
  margin-top: 4px;
  width: 90%;
}

.disabled-element {
  opacity: 0.4;
  pointer-events: none;
}

.contact-support-icn {
  height: 32px;
  width: 32px;

  svg {
    path {
      fill: black;
    }
  }
}

