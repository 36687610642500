.header {
  background: #000;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  height: 48px;
  line-height: 24px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  padding: 0 16px;

  &__content {
    padding-right: 24px;
  }

  &__text {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  &__area-select {
    min-width: 280px;
    width: fit-content;
  }

  &__admin {
    cursor: pointer;
  }

  &__divider {
    border-left: 1px solid rgba(250, 250, 250, 0.5);
    height: 20px;
  }

  &__button {
    background: none;
    border: none;
    color: #fff;
    font-size: 16px;
  }

  a.text-decoration-none:hover {
    text-decoration: none;
  }

  &__logos {
    text-decoration: none;
  }
}
