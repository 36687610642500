.themed-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: 0;
  cursor: pointer;

  &--disabled {
    cursor: default;
  }

  &__layout {
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid #a7a8aa;
    border-radius: 2px;
    transition: 0.3s;
    background-color: #fff;
    flex-shrink: 0;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 8px;
      content: '';
      transform: translate(-50%, -50%);
      transition: 0.3s;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.5L3.5 7L9 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
    }
  }

  &__input {
    display: none;
  }

  &__label {
    padding-left: 8px;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    width: 100%;
  }

  &__label--nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__input:checked + &__layout {
    background: #007cb0;
    border-color: #007cb0;
  }

  &__input:checked + &__layout::after {
    opacity: 1;
  }

  &__input:hover + &__layout {
    border-color: #007cb0;
  }

  &__input:hover:checked + &__layout {
    background: #006894;
    border-color: #006894;
  }

  &__input:active + &__layout {
    background: #ebf5f9;
    border-color: #007cb0;
  }

  &__input:active:checked + &__layout {
    background: #005478;
    border-color: #005478;
  }

  &__input:disabled + &__layout {
    background: #f5f5f5;
    border-color: #a7a8aa;
  }

  &__input:checked:disabled + &__layout {
    background: #a7a8aa;
    border-color: #a7a8aa;
  }

  &__input_indeterminate + &__layout {
    background: #007cb0;
    border-color: #007cb0;
  }

  &__input_indeterminate:hover + &__layout {
    background: #006894;
    border-color: #006894;
  }

  &__input_indeterminate:hover + &__layout {
    background: #005478;
    border-color: #005478;
  }

  &__input_indeterminate + &__layout::after {
    background: url('../../assets/images/MarkLine.svg') no-repeat center / contain;
    opacity: 1;
  }

  &__input_error + &__layout {
    border-color: #da291c;
    background: #fff;
  }
}
