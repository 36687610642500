@import '../../styles/variables.scss';

.ipo-scanner__header {
  display: flex;
  width: 100%;
  padding: 16px 24px;
  gap: 16px;
  align-items: center;
  background-color: $black;
  color: $white;

  &__deloitte_logo {
    height: 24px;
  }

  &__divider {
    height: 16px;
    width: 1px;
    background-color: $dark-grey;
  }

  &__ipo_scanner_logo {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}
