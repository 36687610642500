.option-with-checkbox {
  background-color: #fff;
  padding: 0 !important;
  margin: 0 !important;

  &__label {
    cursor: pointer;
    padding: 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;

    &:hover {
      background: #ebf5f9;
    }
  }
}
