.footer {
  background: #fff;
  border-top: 1px solid #e1e5eb;
  box-sizing: border-box;
  color: #75787b;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;

  p {
    margin-bottom: 5px;
  }

  a {
    color: #40bae7;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__cookies-link#ot-sdk-btn.ot-sdk-show-settings {
    background: none;
    border: none;
    color: #40bae7;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    text-decoration: none;

    &:hover {
      background: none;
      color: #40bae7;
      text-decoration: underline;
    }
  }

  &__content {
    background: #fff;
    padding: 16px 20px;
  }

  &__link {
    color: #53565a;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  &__sub-text {
    color: #75787b;
  }

  .home-footer {
    a {
      border-right: 1px solid #bbbcbc;
      color: #00a3e0;
      font-weight: 600;
      padding: 0 15px;
    }

    a:last-child {
      border-right: none;
    }
  }
}
