.value-container {
  &__values {
    max-width: calc(100% - 30px);
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
