@import '../../styles/variables.scss';

.ipo-scanner__main {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey;

    input[type="radio"] {
        accent-color: $light-blue;
    }
}
