$white: #ffffff;

$grey_9: #75787B;
$grey_10: #63666A;
$grey: #CECECE;
$dark-grey: #53565A;
$additional-grey: #F5F5F5;
$canceled-grey: #a7a8aa;
$cool-grey-2: #D0D0CE;
$cool-grey-4: #BBBCBC;
$cool-grey-6: #63666A;
$cool-grey-7: #A7A8AA;
$outlined-grey: #D6D6D6;
$outlined-grey-2: #EBEBEB;

$black-6per: #F0F0F0;
$black-24per: #C2C2C2;
$black: #000000;

$light-blue: #007CB0;

$green: #86bc25;
$green-5: #009A44;
$primary-green-4: #43B02A;

$orange: #ED8B00;
$orange-light: #FFF7E8;
$orange-peel: #FF9D00;

$red: #DA291C;
$light-red: #da291c;
$additional-red: #e98078;
$opacity-red: #fceeed;
