@import '../../assets/styles/index.css';
@import '../../assets/styles/toast.scss';
@import 'variables';

.ipo-scanner-link {
  background-color: $white;
  max-width: 608px;
  width: 100%;
  padding: 32px 32px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__image {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    text-align: center;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $dark-grey;
    text-align: center;
    margin: 0;
  }

  &__inputs {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;

    .field-label {
      margin-bottom: 8px;
    }

    .themed-input__container {
      width: 66%;
    }

    .themed-button.medium {
      padding: 5px 16px;
    }
  }

  &__button {
    margin-top: 16px;
  }
}

.term {
  color: #007cb0;
  cursor: pointer;
  position: relative;
  display: inline-block;

  &::after {
    content: "Click for explanation";
    position: absolute;
    z-index: 10000;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    padding: 3px 8px;
    background-color: black;
    color: #fff;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
  }

  &::before {
    content: "";
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 33%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

  &:hover::after,
  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
}

