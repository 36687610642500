@import './colors';
@import './screens';

$header-height: 48px;

$content-top-margin-lg: 60px;
$content-top-margin-sm: 60px;
$content-bottom-margin-lg: 120px;
$content-bottom-margin-sm: 90px;
$content-horz-margin-lg: 76px;
$content-horz-margin-sm: 24px;

.cropped-title {
  max-height: 100px;
  overflow-y: auto;
}

.h-25px {
  height: 25px;
}

.h-6px {
  height: 6px;
}

.fs-8 {
  font-size: 12px;
}

.fs-7 {
  font-size: 14px !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.form-control-xsm {
  font-size: 12px;
  height: 24px;
}

.w-10 {
  width: 10%;
}

.font-open-sans {
  font-family: 'Open Sans', sans-serif;
}

.end-5p {
  right: 5px;
}

.content-top-margin {
  margin-top: $header-height + $content-top-margin-lg;

  @include lt-md {
    margin-top: $header-height + $content-top-margin-sm;
  }
}

.content-bottom-margin {
  margin-bottom: $content-bottom-margin-lg;

  @include lt-md {
    margin-bottom: $content-bottom-margin-sm;
  }
}

.content-bottom-padding {
  padding-bottom: $content-bottom-margin-lg;

  @include lt-md {
    padding-bottom: $content-bottom-margin-sm;
  }
}

.content-horz-margin {
  margin-left: $content-horz-margin-lg;
  margin-right: $content-horz-margin-lg;

  @include lt-md {
    margin-left: $content-horz-margin-sm;
    margin-right: $content-horz-margin-sm;
  }
}

.content-horz-padding {
  padding-left: $content-horz-margin-lg;
  padding-right: $content-horz-margin-lg;

  @include lt-md {
    padding-left: $content-horz-margin-sm;
    padding-right: $content-horz-margin-sm;
  }
}

.bg-white {
  background: $white;
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.deloitte-link.MuiTypography-colorPrimary {
  color: #86bc25;
}

.MuiTypography-colorPrimary:hover {
  color: #86bc25;
}

button.deloitte-primary-btn.MuiButton-outlinedPrimary {
  border: 1px solid rgba(134, 188, 37, 0.5);
  color: #86bc25;

  &:hover {
    border: 1px solid #86bc25;
  }
}

button.deloitte-primary-btn {
  background: $color-brand-blue;
  border: none;
  border-radius: 2px;
  color: $white;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 16px;
  text-transform: uppercase;
  white-space: nowrap;

  &.btn-outlined {
    background: $white; // rgba(134, 188, 37, 0.08);
    color: $color-green-05;
  }

  &.btn-red {
    background: #da291c;
    color: $white;
  }

  &.btn-transparent {
    background: transparent;
    border: 1px solid #d0d0ce;
    color: $black;
  }

  &.btn-transparent-blue {
    background: transparent;
    border: none;
    color: $button-blue;
    margin-left: auto;
    text-transform: none;
  }

  .icon-left {
    margin-right: 8px;
  }

  .icon-right {
    margin-left: 8px;
  }
}

button:disabled {
  opacity: 0.4;
}

.deloitte-link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  .icon-left {
    font-size: 23px;
    margin-right: 4px;
    vertical-align: bottom;
  }

  .icon-right {
    font-size: 23px;
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: bottom;
  }
}

.content-container {
  margin: 0 auto;
  max-width: 1280px;
  width: 1280px;

  &.h0 {
    background-color: #fff;
    height: 1px;

    hr {
      background-color: #ebebeb;
      margin: 0 auto;
      width: 984px;
    }
  }

  &.footer__content {
    height: 92px;
  }
}

a {
  color: $color-brand-blue;
}

.label-form {
  color: $black;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.form {
  input {
    border-radius: 0;
    font-size: 13px;
    height: 32px;
    line-height: 24px;
  }
}

.error {
  color: #da291c;
  font-size: 10px;
  line-height: 16px;
}

.error-control {
  background: #fceeed;
  border: 1px solid #da291c;

  .dropdown {
    background: #fceeed;
    border: 1px solid #da291c !important;
  }
}

.dropdown-error {
  background: transparent;
  border: none;
}

.error-wrapper {
  min-height: 16px;
}

.form-group {
  margin-bottom: 0;
}

input[type='checkbox'] {
  // display: none;

  & + label {
    cursor: pointer;
    padding: 0;
    position: relative;
  }

  & + label::before {
    border-radius: 2px;
    content: '';
    display: inline-block;
    height: 16px;
    margin-right: 10px;
    margin-top: 2px;
    vertical-align: text-top;
    width: 16px;
  }
}

.dropdown-form-control {
  .dropdown {
    border: 1px solid #ced4da;
  }
}

.form-control:focus,
.dropdown-form-control .dropdown.open {
  border-color: $button-blue;
  box-shadow: none !important;
  outline: none;
}

.Toastify__toast-container {
  width: 450px;
}

.list-style-icon {
  .icon-ic-check-approve {
    color: #43b02a;
    font-size: 24px;
    padding-right: 9px;
  }

  li {
    font-weight: normal;
    line-height: 24px;
    list-style-type: none;
    margin-bottom: 16px;
  }
}

.textfield-counter {
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  position: relative;

  &__text {
    bottom: -1rem;
    color: #a7a8aa;
    font-size: 0.75rem;
    position: absolute;
    right: 0;

    &--red {
      color: red;
    }
  }
}

::placeholder {
  color: #a7a8aa !important;
}

.secondary-text {
  color: #a7a8aa;
}

.rbt-menu {
  margin-bottom: 2px;
}

.rbt-menu > li a {
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbt-menu > li a:focus {
  outline: none;
}

.rbt-menu-pagination-option {
  text-align: center;
}

.rbt .rbt-input-main::-ms-clear {
  display: none;
}

.rbt-input-multi {
  cursor: text;
  height: auto;
  overflow: hidden;
  position: relative;
}

.rbt-input-multi.focus {
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0;
}

.rbt-input-multi.form-control[disabled] {
  background-color: #e9ecef;
  opacity: 1;
}

.rbtmulti input::placeholder {
  color: #999;
  opacity: 1;
}

.rbtmulti input:input-placeholder {
  color: #999;
}

.rbtmulti input::input-placeholder {
  color: #999;
}

.rbt-input-multi .rbt-input-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  // margin-bottom: -4px;
  // margin-top: -1px;
  overflow: hidden;
  width: 92%;
}

.rbt-input-multi .rbt-input-main {
  height: 20px;
  margin: 1px 0 4px;
}

.rbt-input-multi.input-lg .rbt-input-main,
.rbt-input-multi.form-control-lg .rbt-input-main {
  height: 24px;
}

.rbt-input-multi.input-sm .rbt-input-main,
.rbt-input-multi.form-control-sm .rbt-input-main {
  height: 18px;
}

.rbt-close-lg {
  font-size: 24px;
}

.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 2px;
  color: #1f8dd6;
  display: inline-block;
  line-height: 16px;
  margin: 0 3px 0 0;
  padding: 4px 7px;
  position: relative;
}

.rbt-token-disabled {
  background-color: #ddd;
  color: #888;
  pointer-events: none;
}

.rbt-token-removeable {
  cursor: pointer;

  .rbt-token-remove-button {
    background: none;
    border: none;

    .sr-only {
      display: none;
    }
  }
}

.rbt-token-active {
  background-color: #1f8dd6;
  color: #fff;
  outline: none;
  text-decoration: none;
}

.rbt-loader {
  animation: loader-animation 600ms infinite linear;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  border-top-color: #1f8dd6;
  display: block;
  height: 16px;
  width: 16px;
}

.rbt-loader-lg {
  height: 20px;
  width: 20px;
}

.rbt-aux {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
  /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}

.rbt-aux-lg {
  width: 46px;
}

.rbt-aux .rbt-close {
  margin-top: -4px;
  pointer-events: auto;
  /* Override pointer-events: none; above */
}

.has-aux .rbt-input {
  padding-right: 34px;
}

.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  padding: 0;
}

/* Input Groups */
.input-group > .rbt {
  flex: 1;
}

.input-group > .rbt .rbt-input-hint,
.input-group > .rbt .rbt-aux {
  z-index: 5;
}

.input-group > .rbt:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group > .rbt:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

/* Validation States */
.has-error .rbt-input-multi.focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-warning .rbt-input-multi.focus {
  border-color: #66512c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-success .rbt-input-multi.focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.accordion__button {
  padding-bottom: 33px;
  padding-left: 0;
  &:hover {
    background-color: unset;
  }
}

.accordion__panel {
  padding-left: 16px;
}

@keyframes loader-animation {
  to {
    transform: rotate(1turn);
  }
}
