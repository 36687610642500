.tag-multi-value {
  border-radius: 0 !important;

  .themed-select__multi-value__label {
    padding: 4px 4px 4px 8px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .themed-select__multi-value__remove {
    position: relative;
    min-width: 24px;
    height: 24px;

    &:hover {
      background: #d6d6d6;
    }

    &:active {
      background: #bbbcbc;
    }

    & > svg {
      display: none;
    }

    &::before,
    &::after {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 1px;
      content: '';
      background: #000;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
