@font-face {
  font-family: 'audit-kit-icon-font';
  src:  url('../fonts/audit-kit-icon-font.eot?np9ey4');
  src:  url('../fonts/audit-kit-icon-font.eot?np9ey4#iefix') format('embedded-opentype'),
    url('../fonts/audit-kit-icon-font.ttf?np9ey4') format('truetype'),
    url('../fonts/audit-kit-icon-font.woff?np9ey4') format('woff'),
    url('../fonts/audit-kit-icon-font.svg?np9ey4#audit-kit-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'audit-kit-icon-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-Warning-Circle:before {
  content: "\e913";
}
.ic-Warning-Triangle:before {
  content: "\e914";
}
.ic-Wi-Fi:before {
  content: "\e915";
}
.ic-Zoom-In:before {
  content: "\e916";
}
.ic-Zoom-Out:before {
  content: "\e917";
}
.ic-Mail-Out:before {
  content: "\e918";
}
.ic-Map:before {
  content: "\e919";
}
.ic-Microphone-Mute:before {
  content: "\e91a";
}
.ic-Microphone:before {
  content: "\e91b";
}
.ic-Minus:before {
  content: "\e91c";
}
.ic-Mobile-Phone-1:before {
  content: "\e91d";
}
.ic-Mobile-Phone-2:before {
  content: "\e91e";
}
.ic-Mortarboard:before {
  content: "\e91f";
}
.ic-Mute:before {
  content: "\e920";
}
.ic-Navigation-1:before {
  content: "\e921";
}
.ic-Navigation-2:before {
  content: "\e922";
}
.ic-No-Entry:before {
  content: "\e923";
}
.ic-Not-Equal:before {
  content: "\e924";
}
.ic-Object-Group-Copy:before {
  content: "\e925";
}
.ic-Object-Group:before {
  content: "\e926";
}
.ic-Options:before {
  content: "\e927";
}
.ic-Org-Chart:before {
  content: "\e928";
}
.ic-Paintbrush:before {
  content: "\e929";
}
.ic-Paper-Plane:before {
  content: "\e92a";
}
.ic-Paperclip-2:before {
  content: "\e92b";
}
.ic-Paperclip:before {
  content: "\e92c";
}
.ic-Paragraph:before {
  content: "\e92d";
}
.ic-Paste:before {
  content: "\e92e";
}
.ic-Pen:before {
  content: "\e92f";
}
.ic-Pencil:before {
  content: "\e930";
}
.ic-Percent:before {
  content: "\e931";
}
.ic-Phone:before {
  content: "\e932";
}
.ic-Picture:before {
  content: "\e933";
}
.ic-Pie-Chart-1:before {
  content: "\e934";
}
.ic-Pie-Chart-2:before {
  content: "\e935";
}
.ic-Pin-Map:before {
  content: "\e936";
}
.ic-Player-Fast-Forward:before {
  content: "\e937";
}
.ic-Player-Pause:before {
  content: "\e938";
}
.ic-Player-Play:before {
  content: "\e939";
}
.ic-Player-Rewind:before {
  content: "\e93a";
}
.ic-Player-Skip-Backwards:before {
  content: "\e93b";
}
.ic-Player-Skip-Forward:before {
  content: "\e93c";
}
.ic-Player-Skip-Forwards:before {
  content: "\e93d";
}
.ic-Player-Skip-Rewind:before {
  content: "\e93e";
}
.ic-Player-Stop:before {
  content: "\e93f";
}
.ic-Plot-Graph:before {
  content: "\e940";
}
.ic-Plus:before {
  content: "\e941";
}
.ic-Preferences-2:before {
  content: "\e942";
}
.ic-Preferences:before {
  content: "\e943";
}
.ic-Printer:before {
  content: "\e944";
}
.ic-Profile-2:before {
  content: "\e945";
}
.ic-Profile-Placeholder:before {
  content: "\e946";
}
.ic-Profile:before {
  content: "\e947";
}
.ic-Push-Pin:before {
  content: "\e948";
}
.ic-QR-Code:before {
  content: "\e949";
}
.ic-Question-Mark-2:before {
  content: "\e94a";
}
.ic-Quote-Marks-2:before {
  content: "\e94b";
}
.ic-Quote-Marks:before {
  content: "\e94c";
}
.ic-Record:before {
  content: "\e94d";
}
.ic-Recycle:before {
  content: "\e94e";
}
.ic-Remove-2:before {
  content: "\e94f";
}
.ic-Remove:before {
  content: "\e950";
}
.ic-Route-1:before {
  content: "\e951";
}
.ic-Route-2:before {
  content: "\e952";
}
.ic-Route-3:before {
  content: "\e953";
}
.ic-RSS:before {
  content: "\e954";
}
.ic-Satchel:before {
  content: "\e955";
}
.ic-Save:before {
  content: "\e956";
}
.ic-Scales-Unbalanced:before {
  content: "\e957";
}
.ic-Scales:before {
  content: "\e958";
}
.ic-Scissors:before {
  content: "\e959";
}
.ic-Search:before {
  content: "\e95a";
}
.ic-Send:before {
  content: "\e95b";
}
.ic-Share:before {
  content: "\e95c";
}
.ic-Shield:before {
  content: "\e95d";
}
.ic-Shopping-Bag:before {
  content: "\e95e";
}
.ic-Shopping-Basket:before {
  content: "\e95f";
}
.ic-Smart-Watch:before {
  content: "\e960";
}
.ic-Spanner:before {
  content: "\e961";
}
.ic-Speech:before {
  content: "\e962";
}
.ic-Speedometer:before {
  content: "\e963";
}
.ic-Star-Filled:before {
  content: "\e964";
}
.ic-Star:before {
  content: "\e965";
}
.ic-Stopwatch:before {
  content: "\e966";
}
.ic-Tablet:before {
  content: "\e967";
}
.ic-Tag:before {
  content: "\e968";
}
.ic-Television:before {
  content: "\e969";
}
.ic-Tick-List:before {
  content: "\e96a";
}
.ic-Tick:before {
  content: "\e96b";
}
.ic-Toolbox:before {
  content: "\e96c";
}
.ic-Trolley:before {
  content: "\e96d";
}
.ic-Type-Cursor:before {
  content: "\e96e";
}
.ic-Unlink-2:before {
  content: "\e96f";
}
.ic-Unlink:before {
  content: "\e970";
}
.ic-Unlock:before {
  content: "\e971";
}
.ic-Upload:before {
  content: "\e972";
}
.ic-User:before {
  content: "\e973";
}
.ic-Users:before {
  content: "\e974";
}
.ic-Video:before {
  content: "\e975";
}
.ic-Virtual-Reality:before {
  content: "\e976";
}
.ic-Volume-High:before {
  content: "\e977";
}
.ic-Volume-Low:before {
  content: "\e978";
}
.ic-Volume-Middle:before {
  content: "\e979";
}
.ic-Warning-Argyle:before {
  content: "\e97a";
}
.ic-Flag:before {
  content: "\e97b";
}
.ic-Folder-Incoming:before {
  content: "\e97c";
}
.ic-Folder-Open:before {
  content: "\e97d";
}
.ic-Folder-Outgoing:before {
  content: "\e97e";
}
.ic-Folder:before {
  content: "\e97f";
}
.ic-Folders:before {
  content: "\e980";
}
.ic-Forward:before {
  content: "\e981";
}
.ic-Frame-1:before {
  content: "\e982";
}
.ic-Frame-2:before {
  content: "\e983";
}
.ic-Glassess:before {
  content: "\e984";
}
.ic-Globe:before {
  content: "\e985";
}
.ic-Grid:before {
  content: "\e986";
}
.ic-Hashtag:before {
  content: "\e987";
}
.ic-HDD:before {
  content: "\e988";
}
.ic-Headphones:before {
  content: "\e989";
}
.ic-Headset:before {
  content: "\e98a";
}
.ic-Heart-Filled:before {
  content: "\e98b";
}
.ic-Heart:before {
  content: "\e98c";
}
.ic-Hidden:before {
  content: "\e98d";
}
.ic-House:before {
  content: "\e98e";
}
.ic-ID-Card:before {
  content: "\e98f";
}
.ic-Inbox:before {
  content: "\e990";
}
.ic-Infinity-Loop:before {
  content: "\e991";
}
.ic-Information-2:before {
  content: "\e992";
}
.ic-Information-3:before {
  content: "\e993";
}
.ic-Information:before {
  content: "\e994";
}
.ic-Laptop:before {
  content: "\e995";
}
.ic-Lifebuoy:before {
  content: "\e996";
}
.ic-Light-Bulb-Energy:before {
  content: "\e997";
}
.ic-Light-Bulb:before {
  content: "\e998";
}
.ic-Lightning-Bolt:before {
  content: "\e999";
}
.ic-Like:before {
  content: "\e99a";
}
.ic-Line-Graph:before {
  content: "\e99b";
}
.ic-Link-2:before {
  content: "\e99c";
}
.ic-Link:before {
  content: "\e99d";
}
.ic-List-2:before {
  content: "\e99e";
}
.ic-List:before {
  content: "\e99f";
}
.ic-Lock-Filled:before {
  content: "\e9a0";
}
.ic-Lock:before {
  content: "\e9a1";
}
.ic-Loudhailer:before {
  content: "\e9a2";
}
.ic-Magnet:before {
  content: "\e9a3";
}
.ic-Mail-Closed:before {
  content: "\e9a4";
}
.ic-Mail-In:before {
  content: "\e9a5";
}
.ic-At-Sign:before {
  content: "\e9a6";
}
.ic-3D:before {
  content: "\e9a7";
}
.ic-Archive-Box:before {
  content: "\e9a8";
}
.ic-Asterisk:before {
  content: "\e9a9";
}
.ic-Backup-Complete:before {
  content: "\e9aa";
}
.ic-Backup:before {
  content: "\e9ab";
}
.ic-Banknote:before {
  content: "\e9ac";
}
.ic-Bar-Chart-1:before {
  content: "\e9ad";
}
.ic-Bar-Chart-2:before {
  content: "\e9ae";
}
.ic-Bell:before {
  content: "\e9af";
}
.ic-Bitcoin:before {
  content: "\e9b0";
}
.ic-Bluetooth:before {
  content: "\e9b1";
}
.ic-Book-Closed:before {
  content: "\e9b2";
}
.ic-Book-Open:before {
  content: "\e9b3";
}
.ic-Bookmark-2:before {
  content: "\e9b4";
}
.ic-Bookmark-Filled:before {
  content: "\e9b5";
}
.ic-Bookmark:before {
  content: "\e9b6";
}
.ic-Briefcase:before {
  content: "\e9b7";
}
.ic-Broken-Heart:before {
  content: "\e9b8";
}
.ic-Buffering:before {
  content: "\e9b9";
}
.ic-Calculator:before {
  content: "\e9ba";
}
.ic-Calendar-2:before {
  content: "\e9bb";
}
.ic-Calendar-Minus:before {
  content: "\e9bc";
}
.ic-Calendar-Multiply:before {
  content: "\e9bd";
}
.ic-Calendar-Plus:before {
  content: "\e9be";
}
.ic-Calendar-Tick:before {
  content: "\e9bf";
}
.ic-Calendar:before {
  content: "\e9c0";
}
.ic-Camera:before {
  content: "\e9c1";
}
.ic-Chat:before {
  content: "\e9c2";
}
.ic-Check:before {
  content: "\e9c3";
}
.ic-Clipboard:before {
  content: "\e9c4";
}
.ic-Clock:before {
  content: "\e9c5";
}
.ic-Cloud:before {
  content: "\e9c6";
}
.ic-Code:before {
  content: "\e9c7";
}
.ic-Cog:before {
  content: "\e9c8";
}
.ic-Commenting:before {
  content: "\e9c9";
}
.ic-Copy:before {
  content: "\e9ca";
}
.ic-Credit-Card:before {
  content: "\e9cb";
}
.ic-Crop:before {
  content: "\e9cc";
}
.ic-Cross:before {
  content: "\e9cd";
}
.ic-Currency-Dollar:before {
  content: "\e9ce";
}
.ic-Currency-Euro:before {
  content: "\e9cf";
}
.ic-Currency-Pound:before {
  content: "\e9d0";
}
.ic-Currency-Yen:before {
  content: "\e9d1";
}
.ic-Currency:before {
  content: "\e9d2";
}
.ic-Cursor:before {
  content: "\e9d3";
}
.ic-Delivery:before {
  content: "\e9d4";
}
.ic-Devices:before {
  content: "\e9d5";
}
.ic-Diploma:before {
  content: "\e9d6";
}
.ic-Dislike:before {
  content: "\e9d7";
}
.ic-Display:before {
  content: "\e9d8";
}
.ic-Divide:before {
  content: "\e9d9";
}
.ic-Documents-2:before {
  content: "\e9da";
}
.ic-Documents:before {
  content: "\e9db";
}
.ic-Double-Quote-Marks:before {
  content: "\e9dc";
}
.ic-Download-From-Cloud:before {
  content: "\e9dd";
}
.ic-Download-To-Cloud:before {
  content: "\e9de";
}
.ic-Download:before {
  content: "\e9df";
}
.ic-Drag:before {
  content: "\e9e0";
}
.ic-Edit-Profile:before {
  content: "\e9e1";
}
.ic-Edit:before {
  content: "\e9e2";
}
.ic-Egg-Timer:before {
  content: "\e9e3";
}
.ic-Eject:before {
  content: "\e9e4";
}
.ic-Elevator:before {
  content: "\e9e5";
}
.ic-Enter:before {
  content: "\e9e6";
}
.ic-Equals:before {
  content: "\e9e7";
}
.ic-Eraser:before {
  content: "\e9e8";
}
.ic-Exclamation-Mark:before {
  content: "\e9e9";
}
.ic-Exit:before {
  content: "\e9ea";
}
.ic-External-Link:before {
  content: "\e9eb";
}
.ic-External-Tab:before {
  content: "\e9ec";
}
.ic-Eye:before {
  content: "\e9ed";
}
.ic-Eyedropper:before {
  content: "\e9ee";
}
.ic-Filter:before {
  content: "\e9ef";
}
.ic-Fire:before {
  content: "\e9f0";
}
.ic-First-Aid-Kit:before {
  content: "\e9f1";
}
.ic-Sign-Off-Remove:before {
  content: "\e9f2";
}
.ic-Sign-Off:before {
  content: "\e9f3";
}
.ic-Circle-Tick:before {
  content: "\e9f4";
}
.ic-Envelope-File:before {
  content: "\e9f5";
}
.ic-Input-Field:before {
  content: "\e9f6";
}
.ic-Maximize:before {
  content: "\e9f7";
}
.ic-Minimize:before {
  content: "\e9f8";
}
.ic-Note:before {
  content: "\e9f9";
}
.ic-Align-Center:before {
  content: "\e9fa";
}
.ic-Align-Justify:before {
  content: "\e9fb";
}
.ic-Align-Left:before {
  content: "\e9fc";
}
.ic-Align-Right:before {
  content: "\e9fd";
}
.ic-Indent:before {
  content: "\e9fe";
}
.ic-List-Bulleted:before {
  content: "\e9ff";
}
.ic-List-Numbered:before {
  content: "\ea00";
}
.ic-Redo:before {
  content: "\ea01";
}
.ic-Text-Bold:before {
  content: "\ea02";
}
.ic-Text-Color:before {
  content: "\ea03";
}
.ic-Text-Italic:before {
  content: "\ea04";
}
.ic-Text-Underline:before {
  content: "\ea05";
}
.ic-Undo:before {
  content: "\ea06";
}
.ic-Unindent:before {
  content: "\ea07";
}
.ic-Sort-Alphabetic-Ascending:before {
  content: "\ea08";
}
.ic-Sort-Alphabetic-Descending:before {
  content: "\ea09";
}
.ic-Sort-Amount-Ascending:before {
  content: "\ea0a";
}
.ic-Sort-Amount-Descending:before {
  content: "\ea0b";
}
.ic-Sort-Numeric-Ascending:before {
  content: "\ea0c";
}
.ic-Sort-Numeric-Descending:before {
  content: "\ea0d";
}
.ic-Menu-1:before {
  content: "\ea0e";
}
.ic-Menu-2:before {
  content: "\ea0f";
}
.ic-Menu-3:before {
  content: "\ea10";
}
.ic-Menu-4:before {
  content: "\ea11";
}
.ic-Menu-5:before {
  content: "\ea12";
}
.ic-Menu-6:before {
  content: "\ea13";
}
.ic-Arrow-Double-Down:before {
  content: "\ea14";
}
.ic-Arrow-Double-Left:before {
  content: "\ea15";
}
.ic-Arrow-Double-Right:before {
  content: "\ea16";
}
.ic-Arrow-Double-Up:before {
  content: "\ea17";
}
.ic-Arrow-Down-Left-Turn:before {
  content: "\ea18";
}
.ic-Arrow-Down-Right-Turn:before {
  content: "\ea19";
}
.ic-Arrow-Down:before {
  content: "\ea1a";
}
.ic-Arrow-Left-Down-Turn:before {
  content: "\ea1b";
}
.ic-Arrow-Left-Right:before {
  content: "\ea1c";
}
.ic-Arrow-Left-Up-Turn:before {
  content: "\ea1d";
}
.ic-Arrow-Left:before {
  content: "\ea1e";
}
.ic-Arrow-Right-Corner-Bottom:before {
  content: "\ea1f";
}
.ic-Arrow-Right-Corner-Top:before {
  content: "\ea20";
}
.ic-Arrow-Right-Down-Turn:before {
  content: "\ea21";
}
.ic-Arrow-Right-Up-Turn:before {
  content: "\ea22";
}
.ic-Arrow-Right:before {
  content: "\ea23";
}
.ic-Arrow-Up-Down:before {
  content: "\ea24";
}
.ic-Arrow-Up-Left-Turn:before {
  content: "\ea25";
}
.ic-Arrow-Up:before {
  content: "\ea26";
}
.ic-Caret-Down:before {
  content: "\ea27";
}
.ic-Caret-Left:before {
  content: "\ea28";
}
.ic-Caret-Right:before {
  content: "\ea29";
}
.ic-Caret-Up:before {
  content: "\ea2a";
}
.ic-Chevron-Down:before {
  content: "\ea2b";
}
.ic-Chevron-Left-Skip-Forward:before {
  content: "\ea2c";
}
.ic-Chevron-Left:before {
  content: "\ea2d";
}
.ic-Chevron-Right-Skip-Forward:before {
  content: "\ea2e";
}
.ic-Chevron-Right:before {
  content: "\ea2f";
}
.ic-Chevron-Up:before {
  content: "\ea30";
}
.ic-Decrease-1:before {
  content: "\ea31";
}
.ic-Decrease-2:before {
  content: "\ea32";
}
.ic-Decrease-3:before {
  content: "\ea33";
}
.ic-Decrease-4:before {
  content: "\ea34";
}
.ic-Decrease-5:before {
  content: "\ea35";
}
.ic-Direction:before {
  content: "\ea36";
}
.ic-Directions-1:before {
  content: "\ea37";
}
.ic-Directions-2:before {
  content: "\ea38";
}
.ic-Increase-1:before {
  content: "\ea39";
}
.ic-Increase-2:before {
  content: "\ea3a";
}
.ic-Increase-3:before {
  content: "\ea3b";
}
.ic-Increase-4:before {
  content: "\ea3c";
}
.ic-Increase-5:before {
  content: "\ea3d";
}
.ic-Merge:before {
  content: "\ea3e";
}
.ic-Refresh:before {
  content: "\ea3f";
}
.ic-Reply-All:before {
  content: "\ea40";
}
.ic-Reply:before {
  content: "\ea41";
}
.ic-Return:before {
  content: "\ea42";
}
.ic-Reverse:before {
  content: "\ea43";
}
.ic-Rotate:before {
  content: "\ea44";
}
.ic-Shuffle:before {
  content: "\ea45";
}
.ic-Simple-Arrow-Down:before {
  content: "\ea46";
}
.ic-Simple-Arrow-Left:before {
  content: "\ea47";
}
.ic-Simple-Arrow-Right:before {
  content: "\ea48";
}
.ic-Simple-Arrow-Side:before {
  content: "\ea49";
}
.ic-Simple-Arrow-Up-Down:before {
  content: "\ea4a";
}
.ic-Simple-Arrow-Up:before {
  content: "\ea4b";
}
.ic-Spin:before {
  content: "\ea4c";
}
.ic-Split:before {
  content: "\ea4d";
}
.ic-Document-1:before {
  content: "\e900";
}
.ic-Document-3:before {
  content: "\e901";
}
.ic-File-Archive:before {
  content: "\e902";
}
.ic-File-Audio-1:before {
  content: "\e903";
}
.ic-File-Code:before {
  content: "\e904";
}
.ic-File-DOC:before {
  content: "\e905";
}
.ic-File-Edit:before {
  content: "\e906";
}
.ic-File-Excel:before {
  content: "\e907";
}
.ic-File-Movie:before {
  content: "\e908";
}
.ic-File-New:before {
  content: "\e909";
}
.ic-File-PDF:before {
  content: "\e90a";
}
.ic-File-Picture:before {
  content: "\e90b";
}
.ic-File-Powerpoint:before {
  content: "\e90c";
}
.ic-File-PPT:before {
  content: "\e90d";
}
.ic-File-Template:before {
  content: "\e90e";
}
.ic-File-Visio:before {
  content: "\e90f";
}
.ic-File-Word:before {
  content: "\e910";
}
.ic-File-XLS:before {
  content: "\e911";
}
.ic-File-ZIP:before {
  content: "\e912";
}
