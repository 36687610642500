.field-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  &--required {
    &::after {
      margin-left: 8px;
      display: inline;
      content: '*';
      color: #da291c;
    }
  }
}
