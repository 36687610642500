@import '../../styles/mixins.scss';

.ipo-scanner__page-layout {
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  width: 100vw;
  height: 100vw;
  overflow: auto;

  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;

  &__header {
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  &__main {
    grid-area: main;
  }

  &__footer {
    grid-area: footer;
  }
}
