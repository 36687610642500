@import '../../../styles/colors.scss';

.themed-tooltip {
  .tooltip-inner {
    background-color: rgba($black, 0.9) !important;
    border-radius: 2px;
    padding: 4px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $color-text-alternate-primary;
    text-align: left;
  }

  &.bs-tooltip-start {
    .tooltip-arrow::before {
      border-left-color: rgba($black, 0.9) !important;
    }
  }

  &.bs-tooltip-end {
    .tooltip-arrow::before {
      border-right-color: rgba($black, 0.9) !important;
    }
  }

  &.bs-tooltip-top {
    .tooltip-arrow::before {
      border-top-color: rgba($black, 0.9) !important;
    }
  }

  &.bs-tooltip-bottom {
    .tooltip-arrow::before {
      border-bottom-color: rgba($black, 0.9) !important;
    }
  }
}
