@import "../../../styles/colors";

.themed-button {
  $base: &;
  background: $white;
  border: none;
  border-radius: 2px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-right: 0;
  transition: 0.3s;

  &__full-width {
    width: 100%;
  }

  &__uppercase {
    text-transform: uppercase;
  }

  &__nowrap {
    white-space: nowrap;
  }

  &.large {
    font-size: 14px;
    padding: 8px 16px;
  }

  &.medium {
    padding: 4px 16px;
    font-size: 14px;
  }

  &.small {
    font-size: 12px;
    padding: 0 8px;
  }

  &__solid,
  &__with-icon-solid {
    &.blue {
      background: $color-brand-blue;
      color: $white;

      &:hover,
      &:focus {
        background: $dark-cyan;
        color: $white;
      }

      &:active,
      &#{$base}__active {
        background: #005478;
        color: $white;
      }

      &:disabled {
        background: $canceled-grey;
        opacity: 1;
        color: $white;
      }
    }

    &.red {
      background: $light-red;
      color: $white;

      &:hover {
        background: #c82518;
        color: $white;
      }

      &:active,
      &#{$base}__active {
        background: #b62116;
        color: $white;
      }

      &:disabled {
        background: $canceled-grey;
        color: $white;
        opacity: 1;
      }
    }

    &.grey {
      background-color: $canceled-grey;
      color: $white;
      opacity: 1;
    }
  }

  &__text {
    &.blue {
      background: $white;
      color: $color-brand-blue;

      &:hover,
      &:focus {
        background: $color-hat-control-background;
        color: $color-brand-blue;
      }

      &:active,
      &#{$base}__active {
        background: $color-grey-02;
      }

      &:disabled {
        color: $canceled-grey;
      }
    }

    &.red {
      color: $light-red;

      &:hover {
        background: #fceeed;
      }

      &:active,
      &#{$base}__active {
        background: #f9ddda;
      }

      &:disabled {
        color: $canceled-grey;
      }
    }
  }

  &__with-icon,
  &__with-icon-solid {
    align-items: center;
    background: $white;
    color: $color-brand-blue;
    display: flex;

    &:hover,
    &:focus {
      background: $color-hat-control-background;
      color: $color-brand-blue;
    }

    &:active {
      background: $color-grey-02;
    }

    &:disabled {
      color: $canceled-grey;
    }

    &.large,
    &.medium {
      padding-left: 8px;
    }

    &.small {
      padding-left: 0;
    }

    & svg {
      fill: $color-brand-blue;
    }

    &:disabled {
      color: $canceled-grey;

      & svg {
        fill: $canceled-grey;
      }
    }
  }

  .themed-button__icon-wrapper {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-right: 4px;
    width: 24px;
  }

  &__outline {
    &.large {
      padding: 7px 16px;
    }

    &.medium {
      padding: 3px 16px;
    }

    &.small {
      line-height: 22px;
      padding: 0 16px;
    }

    &.grey {
      background: $white;
      border: 1px solid $canceled-grey;
      color: $black;

      &:hover,
      &:focus {
        background: #ebebeb;
      }

      &:active,
      &#{$base}__active {
        background: #d6d6d6;
        border: 1px solid #d0d0ce;
      }

      &:disabled {
        border: 1px solid $canceled-grey;
        color: $canceled-grey;
      }
    }

    &.blue {
      background: $white;
      border: 1px solid $color-brand-blue;
      color: $color-brand-blue;

      &:hover,
      &:focus {
        background: #ebf5f9;
      }

      &:active,
      &#{$base}__active {
        background: $color-grey-02;
        border: 1px solid $color-brand-blue;
      }

      &:disabled {
        border: 1px solid $canceled-grey;
        color: $canceled-grey;
      }
    }
  }
}
